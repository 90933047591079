<template>
  <b-modal
    id="modal-add-products-form"
    :title="title"
    size="lg"
    hide-footer
    v-model="isModalOpen"
    @hidden="modalClosed"
    @show="showModalAddUpdateProduct"
  >
    <v-form lazy-validation>
      <!-- For manager or president -->
      <div>
        <!-- Employee's name employee's code -->
        <div class="w-100 mb-5">
          <label>Tên sản phẩm</label>
          <b-input
            v-model="name"
            type="text"
            size="sm"
            placeholder="Nhập tên sản phẩm"
            single-line
            hide-details
            :state="validateState('name')"
            :disabled="disabledInputName"
          />
          <b-form-invalid-feedback
            v-if="$v.name.$error"
            :state="validateState('name')"
            >{{ errorMessage }}</b-form-invalid-feedback
          >
        </div>
        <!-- Current store -->

        <div class="w-100 mb-5">
          <label>Chọn cửa hàng</label>
          <vue-multi-select
            style="margin-bottom: 2rem"
            v-model="selectedOptions"
            :options="shopCode"
            placeholder="Chọn cửa hàng muốn crawl sản phẩm"
            option-label="name"
          ></vue-multi-select>
        </div>

        <div
          class="w-100 mb-5"
          v-for="item in selectedOptions"
          :key="item.code"
        >
          <label>{{ item.name }}</label>
          <b-input
            v-model="uploadProductLink[item.code]"
            type="text"
            size="sm"
            placeholder="Nhập link sản phẩm"
            single-line
            hide-details
            @input="searchLink"
          />
        </div>

        <!-- <div class="w-100 mb-5">
          <b-button
            size="sm"
            variant="primary"
            @click="crawlProductByLink()"
            style="fontweight: 600; width: 70px"
            :disabled="disabledButtonSearch"
            >Tìm</b-button
          >
        </div> -->
        <div class="w-100 mb-5">
          <label>Thông tin sản phẩm</label>
          <div id="scroll-table">
            <b-table
              :items="listData"
              :fields="customField"
              :small="true"
              :busy="onLoading"
              responsive
              bordered
              hover
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
              </template>
              <template v-slot:cell(name)="row">
                <span style="display: block">{{ row.item.name }}</span>
              </template>
              <template v-slot:cell(category)="row">
                <div style="text-align: center">
                  <span>{{ row.item.category }}</span>
                </div>
              </template>
              <template #cell()="data">
                <div v-if="!data.value.price" v-html="data.value"></div>
                <a v-else :href="data.value.link" target="_blank">{{
                  data.value.price
                }}</a>
                <!-- <a :href="data.value.link" target="_blank">{{
                  data.value.price
                }}</a> -->
              </template>
              <template v-slot:cell(created_at)>
                <span style="display: block">{{ formatDate(new Date()) }}</span>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <b-button
        size="sm"
        variant="secondary"
        class="mr-3"
        style="width: 100px"
        @click="hideModalAddForm"
        >Hủy
      </b-button>

      <b-button
        size="sm"
        variant="primary"
        class="mr-3"
        style="width: 100px"
        @click="saveProduct"
        :disabled="!listData.length"
        >Lưu
      </b-button>
    </v-form>
  </b-modal>
</template>
<script>
// import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import VueMultiSelect from 'vue-simple-multi-select';
import moment from 'moment';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { VclTable } from 'vue-content-loading';
import debounce from 'debounce';
import { TIME_TRIGGER } from '../../../utils/constants';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: ['showModal', 'selectedItemOpenModel', 'typeModal'],
  components: {
    VueMultiSelect,
    VclTable,
  },
  data() {
    return {
      title: '',
      name: '',
      errorMessage: '',
      onLoading: false,
      dataNotSave: {},
      listData: [],
      oldDataName: '',
      uploadProductLink: {},
      oldDataSelectOptions: [],
      fields: [
        {
          key: 'name',
          label: 'Tên sản phẩm',
          thStyle: {
            color: 'rgb(24, 28, 50)',
            textAlign: 'center',
            width: '300px',
          },
        },
      ],
      selectedOptions: [],
      shopCode: [],
      disabledInputName: false,
    };
  },
  validations: {
    name: {
      required,
    },
  },
  created() {
    this.getStores();
  },

  computed: {
    isModalOpen: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit('close', value);
      },
    },
    filterOptions() {
      if (this.selectedOptions.length > 0) {
        return this.selectedOptions.map((item) => {
          return item.code;
        });
      }
      return [];
    },
    customField() {
      let data = [...this.fields];
      let dataDefault = [
        {
          key: 'created_at',
          label: 'Ngày crawl',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ];
      for (const item of this.shopCode) {
        data.push({
          key: item.code,
          label: item.name,
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        });
      }
      data.push(dataDefault[0]);
      return data;
    },
    dataRequestSearchProductByLink() {
      let dataPush = [];
      for (const item of this.selectedOptions) {
        if (this.uploadProductLink[item.code]) {
          const value = this.uploadProductLink[item.code];
          dataPush.push({ key: item.code, value, name: this.name });
        }
      }
      return dataPush;
    },
    disabledButtonSearch() {
      if (
        this.name &&
        this.name === this.oldDataName &&
        this.filterOptions &&
        this.filterOptions.length > 0 &&
        JSON.stringify(this.filterOptions) ===
          JSON.stringify(this.oldDataSelectOptions)
      ) {
        return true;
      }
      return false;
    },
    itemSelect() {
      return { ...this.selectedItemOpenModel };
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      console.log($dirty, $error);
      return $dirty ? !$error : null;
    },
    modalClosed() {
      this.$emit('close', this.modelOpen);
    },
    showModalAddUpdateProduct() {
      this.name = '';
      this.uploadProductLink = {};
      this.selectedOptions = [];
      this.listData = [];
      this.title = 'Thu thập thêm thông tin sản phẩm';
      this.disabledInputName = false;
      if (this.typeModal === 'update') {
        this.title = 'Cập nhật giá sản phẩm';
        this.name = this.itemSelect.name;
        this.disabledInputName = true;
        this.listData.push(this.itemSelect);
      }
    },
    debounceSearch: debounce(function() {
      this.mainCrawlProductByLink();
    }, TIME_TRIGGER),
    searchLink() {
      this.debounceSearch();
    },
    hideModalAddForm() {
      this.$bvModal.hide('modal-add-products-form');
      this.name = '';
      this.listData = [];
      this.oldDataName = '';
      this.oldDataSelectOptions = [];
      this.dataNotSave = {};
      this.selectedOptions = [];
      this.uploadProductLink = {};
    },
    async getStores() {
      try {
        const response = await ApiService.get('/tool/shop-code');
        this.shopCode = response.data.data;
      } catch (error) {
        makeToastFaile('Lỗi! Không tìm thấy cửa hàng');
      }
    },
    async crawlProductByName() {
      try {
        if (!this.name) {
          makeToastFaile('Vui lòng nhập tên sản phẩm !');
        } else if (this.filterOptions.length === 0) {
          makeToastFaile('Vui lòng chọn cửa hàng !');
        } else {
          this.onLoading = true;
          this.oldDataName = this.name;
          this.oldDataSelectOptions = this.filterOptions;
          let query = {
            name: this.name,
            options: this.filterOptions,
          };
          const response = await ApiService.query('/tool/crawl-product-name', {
            params: query,
          });
          this.dataNotSave[response.data.data[0]['name']] =
            response.data.data[0];
          this.listData = Object.values(this.dataNotSave);
          this.onLoading = false;
          // return response;
        }
      } catch (error) {
        this.onLoading = false;
        console.log(('crawl sản phẩm theo tên:', error));
        makeToastFaile('Không crawl được sản phẩm này');
      }
    },
    async mainCrawlProductByLink() {
      this.$v.$touch();
      if (this.typeModal === 'update') {
        await this.crawlProductByLinkModalUpdate();
      }
      if (this.typeModal === 'create') {
        await this.crawlProductByLinkModalCreate();
      }
    },
    async crawlProductByLinkModalCreate() {
      try {
        if (this.$v.name.$error) {
          this.errorMessage = 'Tên không được để trống!';
        }
        if (!this.name) {
          makeToastFaile(`Vui lòng nhập tên sản phẩm`);
          return;
        }
        if (!this.selectedOptions.length) {
          makeToastFaile(`Vui lòng chọn cửa hàng`);
          return;
        }
        const { data } = await ApiService.post('/tool/crawl-product-link', {
          products: this.dataRequestSearchProductByLink,
        });
        if (!data.data.length) {
          makeToastFaile(`Không tìm thấy sản phẩm`);
          return;
        }
        this.dataNotSave[this.name.trim()] = {};
        // lấy category của sản phẩm đầu tiên trả về
        this.dataNotSave[this.name.trim()]['category'] = data.data[0].category;
        this.dataNotSave[this.name.trim()]['name'] = this.name;
        for (const item of data.data) {
          this.dataNotSave[this.name.trim()][item.shop_code] = {
            link: item['url'],
            price: item['price'],
          };
        }
        this.listData = Object.values(this.dataNotSave);
        console.log(this.dataNotSave);
        this.disabledSave = false;
        makeToastSuccess(`Tìm sản phẩm thành công`);
      } catch (e) {
        console.log(e);
        makeToastFaile(`Không tìm thấy sản phẩm`);
      }
    },
    async crawlProductByLinkModalUpdate() {
      try {
        const { data } = await ApiService.post('/tool/crawl-product-link', {
          products: this.dataRequestSearchProductByLink,
        });
        if (!data.data.length) {
          makeToastFaile(`Không tìm thấy sản phẩm`);
          return;
        }
        for (const item of data.data) {
          this.listData[0][item.shop_code] = {
            link: item['url'],
            price: item['price'],
          };
        }
        this.disabledSave = false;
        makeToastSuccess(`Tìm sản phẩm thành công`);
      } catch (e) {
        makeToastFaile(`Không tìm thấy sản phẩm`);
      }
    },
    async saveProduct() {
      if (this.typeModal === 'update') {
        await this.saveProductModalUpdate();
      }
      if (this.typeModal === 'create') {
        await this.saveProductModalCreate();
      }
    },
    async saveProductModalCreate() {
      try {
        const body = {
          products: this.listData,
        };
        await ApiService.post('/tool/save-product', body);
        makeToastSuccess(`Lưu sản phẩm thành công`);
        this.hideModalAddForm();
        this.$emit('getProductCrawl');
      } catch (error) {
        makeToastFaile(`Lỗi! ${error.response.data.message}`);
      }
    },
    async saveProductModalUpdate() {
      try {
        const product = this.listData[0];
        await ApiService.post('/tool/update-product-compare', { product });
        this.hideModalAddForm();
        makeToastSuccess(`Cập nhật sản phẩm thành công`);
        this.$emit('getProductCrawl');
      } catch (e) {
        makeToastFaile(`Lỗi! ${e.message}`);
      }
    },
    formatDate: function(dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    onBack() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-style {
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
.muti-basic-custom .rounded {
  border-radius: 0.28rem !important;
}
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
#modal-add-products-form {
  .form-control.is-invalid {
    border-color: #f64e60 !important;
  }
  #scroll-table ::v-deep() {
    table {
      margin: 0;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      th {
        position: relative;
        z-index: 1;
      }

      td,
      th {
        vertical-align: middle;
        padding: 0.75rem;
      }

      th:first-child {
        position: sticky !important;
        left: 0;
        z-index: 2;
        background-color: white;
      }

      td:first-child {
        position: sticky;
        left: 0;
        z-index: 0;
        background-color: white;
      }

      .font-size-setting {
        font-size: 0.86rem;
      }
    }
  }
}
</style>
